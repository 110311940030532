<template>
  <div
    :class="{
      isInvalid: field.showValidation == true && field.isValid === false,
    }"
  >
    <label>{{ label }}</label>
    <input
      :class="{
        isInvalid: field.showValidation == true && field.isValid === false,
      }"
      type="text"
      v-on="$listeners"
      :value="field.value"
      @input="$emit('update', $event.target.value)"
    />

    <div
      class="validationMessage"
      v-if="field.showValidation == true && field.isValid === false"
    >
      {{ field.message }}
    </div>
  </div>
</template>
  
  
  <script>
export default {
  props: ["field", "label"],
  model: {
    event: "update",
  },
};
</script>