<template>
  <div>
    <div v-for="platform in platforms" :key="platform.id" class="form_checkbox">
      <input
        type="checkbox"
        title="Please Accept T&amp;Cs"
        :id="'select_' + platform.id"
        @change="$emit('platformChanged')"
        v-model="platform.selected"
      />
      <label :for="'select_' + platform.id">{{ platform.name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: ["platforms"],
  emits: ["platformChanged"],
};
</script>
