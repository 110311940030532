import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'

Vue.prototype.$http = axios
Vue.config.productionTip = false

// Get initial prop values from the root element
const el = document.querySelector('#app');

const show_additional_information = (el.getAttribute('data-show-additional-information') === "true") || false;
const show_tpsp_internal_id = (el.getAttribute('data-show-tpsp-internal-id') === "true") || false;
const tpsp_name = el.getAttribute('data-tpsp-name') || '';
const platform_request_email_additional_cc = el.getAttribute('data-platform-request-email-additional-cc') || '';
const api_version = el.getAttribute('data-api-version') || 'both';


const finio_product_valuations = (el.getAttribute('data-finio-product-valuations') === "true") || true;
const finio_product_transactions = (el.getAttribute('data-finio-product-transactions') === "true") || false;
const finio_product_history = (el.getAttribute('data-finio-product-history') === "true") || false;
const finio_product_income_statements = (el.getAttribute('data-finio-product-income-statements') === "true") || false;


new Vue({
  render: h => h(App, {
    props: {
      api_version,
      tpsp_name,
      show_additional_information,
      platform_request_email_additional_cc,
      show_tpsp_internal_id,
      finio_product_valuations,
      finio_product_transactions,
      finio_product_history,
      finio_product_income_statements
    }
  }),
}).$mount('#app')
