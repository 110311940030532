<template>
  <div id="app" ref="appTop">
    <div id="steps">
      <!-- Step 1 -->
      <div id="step1" ref="step1">
        <div class="container">
          <div class="gridContainer clearfix">
            <div v-html="includes.intro"></div>

            <!-- Personal Details -->
            <div class="form_wrap" ref="step1a">
              <h2>Your Details</h2>

              <TextField class="form_col2 l2" label="First Name:*" v-model="user.firstname.value"
                :field="user.firstname" @input="validateStep1()" @blur="
                  validateStep1();
                user.firstname.showValidation = true;
                " />

              <TextField class="form_col2 last" label="Surname:*" v-model="user.surname.value" :field="user.surname"
                @input="validateStep1()" @blur="
                  validateStep1();
                user.surname.showValidation = true;
                " />

              <TextField class="form_col2 l2" label="Telephone:*" v-model="user.telephone.value" :field="user.telephone"
                @input="validateStep1()" @blur="
                  validateStep1();
                user.telephone.showValidation = true;
                " />

              <TextField class="form_col2 last" label="Email:*" v-model="user.email.value" :field="user.email"
                @input="validateStep1()" @blur="
                  validateStep1();
                user.email.showValidation = true;
                " />

              <TextField class="form_col2 l2" label="Company Name:*" v-model="user.company_name.value"
                :field="user.company_name" @input="validateStep1()" @blur="
                  validateStep1();
                user.company_name.showValidation = true;
                " />

              <TextField class="form_col2 last" label="FCA Number(s):*" v-model="user.fca_number.value"
                :field="user.fca_number" @input="validateStep1()" @blur="
                  validateStep1();
                user.fca_number.showValidation = true;
                " />

              <TextField class="form_col2" label="TPSP Internal Reference Number:*" v-if="this.show_tpsp_internal_id"
                v-model="user.tpsp_internal_id.value" :field="user.tpsp_internal_id" @input="validateStep1()" @blur="
                  validateStep1();
                user.tpsp_internal_id.showValidation = true;
                " />
            </div>

            <!-- Platforms -->
            <div class="form_wrap" ref="step1b">
              <div v-html="includes.platforms_intro_available"></div>

              <div class="columns">
                <div class="form_checkbox_wrap clearfix">
                  <div v-html="includes.platform_authorization_header"></div>

                  <div class="columns1">
                    <h3>Your Platform(s) of choice:</h3>
                  </div>
                  <div>
                    <!-- Start Platform Loop -->
                    <div v-for="platform in platforms.filter((item) => {
                      return item.available == true && item.hide !== true;
                    })" :key="platform.id" class="form_checkbox">
                      <input type="checkbox" title="Please Accept T&amp;Cs" :id="'select_' + platform.id"
                        @change="$emit('platformChanged')" v-model="platform.selected" />
                      <label :for="'select_' + platform.id">{{
                        platform.name
                        }}</label>

                      <div v-if="platform.selected">
                        <div style="display: flex; width: 100%">
                          <div style="
                              flex: 1 1 0%;
                              margin: 0 0px 0 40px;
                              background: white;
                            ">
                            <!-- Platform Control -->
                            <div class="platform" style="padding: 20px" v-if="platform.available" :class="{
                              platformIsInvalid:
                                !validatePlatformSelection(platform) &&
                                validation.step1.show_platform_validation,
                            }">
                              <div v-if="platform.authorisation.type == 'email'">
                                <div style="
                                    display: flex;
                                    align-items: flex-start;
                                    padding-top: 4px;
                                    /* margin: 22px 0; */
                                  ">
                                  <span style="
                                      display: inline-block;
                                      width: 38px;
                                      font-size: 30px;
                                      margin-left: 2px;
                                    "><strong>→</strong></span>

                                  <a target="_blank" rel="noopener noreferrer" @click.prevent="
                                    handleEmailLinkClick(platform)
                                    "><strong>Click here to authorise this platform via
                                      email</strong>
                                  </a>
                                </div>
                                <div style="
                                    display: flex;
                                    align-items: flex-start;
                                    margin-top: 22px;
                                  ">
                                  <input type="checkbox" :id="'confirm-' + platform.id"
                                    :disabled="!platform.authorise_clicked" v-model="platform.authorisation_confirmed"
                                    v-on:change="
                                      validateStep1();
                                    $forceUpdate();
                                    " />
                                  <label :for="'confirm-' + platform.id">I confirm I have sent the authorisation
                                    email using the link above</label>
                                </div>
                              </div>

                              <div v-if="platform.authorisation.type == 'pdf'">
                                <div v-if="platform.authorisation.pdf.url !== ''">
                                  <div style="
                                      display: flex;
                                      align-items: flex-start;
                                      padding-top: 4px;
                                      /* margin: 22px 0; */
                                    ">
                                    <span style="
                                        display: inline-block;
                                        width: 38px;
                                        font-size: 30px;
                                        margin-left: 2px;
                                      "><strong>→</strong></span>

                                    <a target="_blank" :href="'/global/downloads/datasheets/' +
                                      platform.authorisation.pdf.url
                                      " @click="platform.authorise_clicked = true"
                                      v-on:click="$forceUpdate()"><strong>Authorise this platform via PDF
                                        form</strong>
                                    </a>
                                  </div>

                                  <div style="
                                      display: flex;
                                      align-items: flex-start;
                                      margin-top: 22px;
                                    ">
                                    <input type="checkbox" :id="'confirm-' + platform.id"
                                      :disabled="!platform.authorise_clicked" v-model="platform.authorisation_confirmed"
                                      v-on:change="
                                        $forceUpdate();
                                      validateStep1();
                                      " />
                                    <label :for="'confirm-' + platform.id">I confirm I have sent the authorisation
                                      pdf from the link above</label>
                                  </div>
                                </div>
                                <div v-else>
                                  <p>
                                    <strong style="color: #990000">
                                      →&nbsp;&nbsp;Authorisation PDF not
                                      uploaded.
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div v-if="platform.available == false">
                              <p>
                                This platform is not yet available via Finio,
                                your interest in having their data in your
                                solution will be registered.
                              </p>
                            </div>
                          </div>
                          <div style="
                              background: rgba(255, 255, 255, 0.5);
                              padding: 0 20px;
                              display: flex;
                              align-items: center;
                            ">
                            <div v-if="platform.data_sheet.url !== ''">
                              <p style="">
                                <a :href="'/global/downloads/datasheets/' +
                                  platform.data_sheet.url
                                  " target="_blank" style="display: flex; align-items: center">
                                  <img src="/images/icon_download.png" alt="" style="
                                      height: 20px;
                                      width: auto;
                                      margin-right: 6px;
                                    " />
                                  <span><strong>View the Platform Guide</strong></span>
                                </a>
                              </p>
                            </div>
                            <div v-else>
                              <strong style="color: red">→&nbsp;&nbsp;Platform datasheet not uploaded.
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="validationMessage" style="margin-left: 40px" v-if="
                        !validatePlatformSelection(platform) &&
                        validation.step1.show_platform_validation
                      ">
                        Please confirm that you have Authorised this platfom.
                      </div>
                    </div>
                    <!-- End Platform Loop -->
                  </div>
                </div>
              </div>
            </div>

            <!-- Terms and Conditions -->
            <div class="form_wrap" ref="step1c">
              <div class="columns1">
                <h3>Terms and Conditions</h3>
                <p>
                  Click the button below to view and accept our Terms and
                  Conditions.
                </p>
              </div>
              <!-- Terms and conditions -->
              <div class="columns1">
                <!-- Trigger/Open The Modal -->
                <div v-if="user.terms.accepted == false">
                  <input type="button" class="link-button" value="Accept T&Cs" :disabled="user.terms.required == false"
                    @click="clickedBtnAcceptTandC" />
                  <div class="validationMessage" v-if="
                    validation.step1.terms_valid == false &&
                    user.terms.showValidation &&
                    user.terms.required == true
                  ">
                    {{ user.terms.message }}
                  </div>
                </div>
              </div>
              <div class="columns1 text-green" v-if="user.terms.accepted && user.terms.required"
                style="margin-top: 10px">
                <img src="/images/icon_tick.png" alt="Terms Accepted" />&nbsp;&nbsp;
                <a target="_blank" href="/global/downloads/Finio-Terms-and-Conditions.pdf">Terms and Conditions</a>
                accepted.
              </div>
            </div>

            <!-- Platforms unavailable -->
            <div class="form_wrap" ref="step-1d" v-if="show_additional_information">
              <div v-html="includes.platforms_intro_other"></div>

              <div class="columns">
                <div class="form_checkbox_wrap clearfix">
                  <PlatformList class="unavailable-platforms-wrapper" :platforms="platforms.filter((item) => {
                    return item.available == false && item.hide !== true;
                  })
                    " @platformChanged="platformChanged()"></PlatformList>
                </div>
              </div>

              <div class="form_col1">
                <label for="additional_information">
                  <p v-html="includes.additional_information_text"></p>
                </label>
                <textarea v-model="user.additional_information.value"></textarea>
              </div>
            </div>

            <!-- Submit Button -->
            <div class="columns1" style="text-align: right">
              <input type="button" class="link-button-submit" value="SUBMIT" v-on:click="step1Sumbit" />
            </div>
          </div>
        </div>

        <div class="container grey" v-if="false">
          <div class="gridContainer clearfix">
            <div v-html="includes.platform_authorization_header"></div>

            <div class="columns1">
              <h3>Your Platform(s) of choice:</h3>
            </div>

            <div class="form_choice" v-for="platform in platforms.filter((item) => {
              return item.selected == true;
            })" :key="platform.id" :class="{ confirmed: platform.authorisation_confirmed }"></div>

            <div class="columns1">
              <div class="columns2-buttons">
                <input type="button" class="link-button-submit" value="BACK" v-on:click="step2Back" /><br />
                For updates click ‘<strong>Back</strong>’.
              </div>

              <div class="columns2-buttons last" style="text-align: right">
                <input type="button" class="link-button-submit" value="FINISH" v-on:click="step2Finish()" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Thaks Page -->
      <div id="step2" ref="step2">
        <div class="container grey">
          <div class="gridContainer clearfix">
            <div v-html="includes.thanks"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- T&C Modal Modal -->
    <div id="myModal" class="modal terms-and-conditions" ref="modal_t_and_c" @click="hideModal($event)">
      <!-- Modal content -->
      <div class="modal-content">
        <div class="modal-header">
          <span class="close" @click="closeModal()">&times;</span>
          <h3>INTERMEDIARY SERVICES – TERMS AND CONDITIONS</h3>
        </div>

        <div class="modal-body">
          <div v-html="includes.finio_terms_and_conditions"></div>
          <div class="form_modal">
            <input name="" type="checkbox" v-model="user.terms.accepted" />
            <label>By clicking this box I confirm I have reviewed and accept the
              FINIO terms and conditions which apply to the FINIO flow of data
              into our system.</label>
          </div>
        </div>

        <div class="modal-footer">
          <span class="close" @click="closeModal()">Close</span>
          <span>
            <a href="/global/downloads/Finio-Terms-and-Conditions.pdf"><img src="/images/icon_download.png" alt=""
                align="absmiddle" /></a>

            &nbsp;<a href="/global/downloads/Finio-Terms-and-Conditions.pdf" target="_blank"><strong
                class="hide-tabletsm-mobile">Download T&amp;Cs (PDF)</strong></a>
            <!--
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="#" onclick="window.print();return false;">
              <img src="images/icon_print.png" alt="" align="absmiddle" />
            </a>
            &nbsp;&nbsp;<a href="#" onclick="window.print();return false;">
              <strong class="hide-tabletsm-mobile">Print T&amp;Cs</strong>
            </a>
            &nbsp;
             -->
          </span>
        </div>
      </div>
      <!--end Model Content-->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { gsap } from "gsap";

import PlatformList from "./components/PlatformList.vue";
import TextField from "./components/TextField.vue";

export default {
  name: "AppFinioForm",
  components: { PlatformList, TextField },
  props: {
    // Form Opitions
    show_additional_information: { type: Boolean, default: false },
    show_tpsp_internal_id: { type: Boolean, default: false },
    tpsp_name: { type: String, default: "Your service provider" },
    platform_request_email_additional_cc: { type: String, default: "" },

    // API Details
    api_version: { type: String, default: "1" },
    finio_product_valuations: { type: Boolean, default: true },
    finio_product_transactions: { type: Boolean, default: false },
    finio_product_history: { type: Boolean, default: false },
    finio_product_income_statements: { type: Boolean, default: false },
  },
  data() {
    return {
      dev: true,
      debug: "",
      step: 1,
      includes: {
        intro: "",
        thanks: "",
        platforms_intro_available: "",
        platforms_intro_other: "",
        additional_information_text: "",
        platform_authorization_header: "",
        finio_terms_and_conditions: "",
      },
      user: {
        firstname: {
          fieldName: "First Name",
          value: "",
          isValid: false,
          showValidation: false,
          message: "First Name is required",
        },
        surname: {
          fieldName: "Surname",
          value: "",
          isValid: false,
          showValidation: false,
          message: "Surname is required",
        },
        telephone: {
          fieldName: "Telephone",
          value: "",
          isValid: false,
          showValidation: false,
          message: "Telephone is required",
        },
        email: {
          fieldName: "Email",
          value: "",
          isValid: false,
          showValidation: false,
          message: "Email is required",
          messageRequired: "Email is required",
          messageValidEmail: "A valid Email is required",
        },
        company_name: {
          fieldName: "Company Name",
          value: "",
          isValid: false,
          showValidation: false,
          message: "Company Name is required",
        },
        fca_number: {
          fieldName: "FCA Number",
          value: "",
          isValid: false,
          showValidation: false,
          message: "FCA Number is required",
        },
        tpsp_internal_id: {
          fieldName: "TPSP Internal Reference Number",
          value: "",
          isValid: false,
          showValidation: false,
          message: "TPSP Internal Reference Number is required",
        },
        additional_information: {
          fieldName: "Additional Information",
          value: "",
        },
        terms: {
          fieldName: "Terms and Conditions",
          required: true,
          accepted: false,
          showValidation: false,
          message: "Accepting the Terms and Conditions is required",
        },
      },
      // platformsGlobal: [],
      platforms: [],
      local_platform_customisations: false,
      validation: {
        step1: {
          show_platform_validation: false,
          personal_details_valid: false,
          platform_selected_valid: false,
          terms_valid: false,
          authorised_all: false,
        },
      },
      email: {
        alwaysCC: "support@finio.co.uk",
      },
    };
  },

  async beforeMount() {
    await this.getGlobalPlatforms();
    await this.getLocalPlatformCustomisations();
    await this.initProviders();
    await this.loadLocalcontent();
    this.getUrlParameters();
  },

  async mounted() {
    this.step1Load();
    window.addEventListener("resize", this.handleResize);

    // Trying to fix the resize happening before the step 1 has loaded.
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.setAppHeight("step1");
    });
    document.addEventListener("DOMContentLoaded", () => {
      this.setAppHeight("step1");
    });
    setTimeout(() => {
      this.setAppHeight("step1");
    }, 2000);
  },

  methods: {
    async getGlobalPlatforms() {
      let json_path = "data/global_platforms.json";
      let live_json_path = "/global/" + json_path;

      await this.$http.get(live_json_path).then((response) => {
        console.log("Loaded global providers list.");
        this.platforms = response.data;
        return true;
      });

      return false;
    },

    async getLocalPlatformCustomisations() {
      console.log("Checking for local platform customisations file.");
      // const folder = location.pathname.split("/")[1];
      // let json_path = "/" + folder + "/data/customise_platforms.json";
      let json_path = "data/customise_platforms.json";
      try {
        // Try local folder first.
        await this.$http.get(json_path).then((response) => {
          this.local_platform_customisations = response.data;
          console.log("Loaded local platform customisations file.");
        });
      } catch (error) {
        console.log("Local platform customisations file not found.");
        // console.log(error);
        // console.log(json_path);
      }
    },

    async initProviders() {
      // Add additional properties to platform
      for (var platform in this.platforms) {
        Vue.set(this.platforms[platform], "key", this.platforms[platform].id);
        Vue.set(this.platforms[platform], "selected", false);
        Vue.set(this.platforms[platform], "show_validation", false);
        Vue.set(this.platforms[platform], "authorise_clicked", false);
        Vue.set(this.platforms[platform], "force_refresh", 0);

        if (this.platforms[platform].available) {
          Vue.set(this.platforms[platform], "authorisation_confirmed", false);
        } else {
          Vue.set(this.platforms[platform], "authorisation_confirmed", true);
        }
      }

      // do we have local site customisations?
      if (this.local_platform_customisations !== false) {
        // Merge 2 objects (local take precident)
        let platform_merging_into = null;
        for (platform in this.local_platform_customisations) {
          console.log(
            "Customise: " + this.local_platform_customisations[platform].id
          );

          platform_merging_into = this.platforms.filter(
            (item) =>
              item.id === this.local_platform_customisations[platform].id
          )[0];

          console.log(this.local_platform_customisations[platform].name);

          // Merge fields
          const propertiesToMerge = ["name", "hide", "available"];
          for (const property of propertiesToMerge) {
            if (
              Object.prototype.hasOwnProperty.call(
                this.local_platform_customisations[platform],
                property
              )
            ) {
              platform_merging_into[property] =
                this.local_platform_customisations[platform][property];
            }
          }
        }

        console.log("Applied Platform customisations.");
        // console.log(this.platforms);

        this.$forceUpdate();
      }
    },

    async loadLocalcontent() {
      this.includes.intro = await this.fetchInclude("intro");
      this.includes.thanks = await this.fetchInclude("thanks");
      this.includes.platforms_intro_available = await this.fetchInclude(
        "platforms-intro-available"
      );
      this.includes.platforms_intro_other = await this.fetchInclude(
        "platforms-intro-other"
      );
      this.includes.additional_information_text = await this.fetchInclude(
        "additional-information-text"
      );
      this.includes.platform_authorization_header = await this.fetchInclude(
        "platform-authorization-header"
      );
      this.includes.finio_terms_and_conditions = await this.fetchInclude(
        "finio-terms-and-conditions"
      );
      this.includes.thanks = await this.fetchInclude("thanks");
    },

    async fetchInclude(fileName) {
      let file_path = "includes/" + fileName + ".html";

      let path_live_local = file_path;
      let path_live_global = "/global/" + file_path;

      let return_value = "";

      // Try live local folder first.
      try {
        // console.log("Look for Live local include: " + path_live_local);
        await this.$http.get(path_live_local).then((response) => {
          console.log("Local Include: " + fileName + ".html");
          return_value = response.data;
        });
      } catch (error) {
        // Try live global local
        // console.log("Local Include not found: " + fileName);
        try {
          // console.log("look for Live Global include: " + path_live_global);
          await this.$http.get(path_live_global).then((response) => {
            console.log("Global Include: " + fileName + ".html");
            return_value = response.data;
          });
        } catch (error) {
          console.log("Not Found: Live Global Include: " + fileName + ".html");
        }
      }
      // replace {TPSP_Name} in includes.
      return return_value.replace(/\{TPSP_Name\}/g, this.tpsp_name);
    },

    getUrlParameters() {
      const urlParams = new URLSearchParams(window.location.search);
      const tpspid = urlParams.get("tpspid");

      if (tpspid) {
        this.user.tpsp_internal_id.value = tpspid;
      }
    },

    step1Load() {
      this.$refs["step1"].classList.add("active");
      this.setAppHeight("step1");

      gsap.fromTo(
        this.$refs["step1"],
        // { scale: 0.95, autoAlpha: 0, y: 20, transformOrigin: "top" },
        // { scale: 1, autoAlpha: 1, y: 0, duration: 2, ease: "power3.out" }
        { scale: 1, autoAlpha: 0, y: 0, transformOrigin: "top" },
        { scale: 1, autoAlpha: 1, y: 0, duration: 0.5, ease: "power3.out" }
      );
    },
    step1Sumbit() {
      // Make all fields show validation
      this.user.firstname.showValidation = true;
      this.user.surname.showValidation = true;
      this.user.telephone.showValidation = true;
      this.user.email.showValidation = true;
      this.user.company_name.showValidation = true;
      this.user.fca_number.showValidation = true;

      if (this.show_tpsp_internal_id) {
        this.user.tpsp_internal_id.showValidation = true;
      }

      this.validation.step1.show_platform_validation = true;
      this.user.terms.showValidation = true;

      this.validateStep1();

      let step1_valid = true;
      if (!this.validation.step1.personal_details_valid) {
        this.scrollTo("step1a", "smooth");
        step1_valid = false;
      } else if (!this.validation.step1.platform_selected_valid) {
        alert("Please select a platform.");
        this.scrollTo("step1b", "smooth");
        step1_valid = false;
      } else if (!this.validation.step1.authorised_all) {
        this.scrollTo("step1c", "smooth");
        step1_valid = false;
      } else if (!this.validation.step1.terms_valid) {
        this.scrollTo("step1c", "smooth");
        step1_valid = false;
      }

      if (!step1_valid) {
        this.setAppHeight("step1");
        return false;
      }

      // Submit to our API (to send to Zoho)
      if (!this.submit_to_api()) {
        alert("error submitting data to api");
        step1_valid = false;
        return false;
      }

      // Goto Step 2 (thanks)
      this.step = 2;
      this.$forceUpdate();

      this.setAppHeight("step2");

      gsap.fromTo(
        this.$refs["step1"],
        { autoAlpha: 1, x: 0 },
        { autoAlpha: 0, x: 0, duration: 0.5, ease: "power3.out" }
      );
      gsap.fromTo(
        this.$refs["step2"],
        { autoAlpha: 0, x: 0 },
        { autoAlpha: 1, x: 0, duration: 0.5, ease: "power3.out" }
      );

      this.$refs["step1"].classList.remove("active");
      this.$refs["step2"].classList.add("active");
      this.scrollTo("appTop");
    },

    validateStep1() {
      // console.log("valideate step 1");

      // Validate Personal Details
      //---------------------------
      this.validation.step1.personal_details_valid = true;

      if (!this.validateRequired(this.user.firstname)) {
        this.validation.step1.personal_details_valid = false;
      }

      if (!this.validateRequired(this.user.surname)) {
        this.validation.step1.personal_details_valid = false;
      }
      if (!this.validateRequired(this.user.telephone)) {
        this.validation.step1.personal_details_valid = false;
      }

      if (!this.validateRequired(this.user.email)) {
        this.validation.step1.personal_details_valid = false;
        this.user.email.message = this.user.email.messageRequired;
      } else if (!this.validateEmail(this.user.email)) {
        this.user.email.message = this.user.email.messageValidEmail;
        this.validation.step1.personal_details_valid = false;
      }

      if (!this.validateRequired(this.user.company_name)) {
        this.validation.step1.personal_details_valid = false;
      }
      if (!this.validateRequired(this.user.fca_number)) {
        this.validation.step1.personal_details_valid = false;
      }

      if (
        this.show_tpsp_internal_id &&
        !this.validateRequired(this.user.tpsp_internal_id)
      ) {
        this.validation.step1.personal_details_valid = false;
      }

      // Validate there is at least one platform selected.
      // ------------------------------
      this.validation.step1.platform_selected_valid = false;
      for (const platform of this.platforms) {
        if (platform.selected) {
          this.validation.step1.platform_selected_valid = true;
          break;
        }
      }

      // validate Selected Platforms have been authorised.
      //---------------------------------------------------
      this.validation.step1.authorised_all = true;

      for (const platform of this.platforms) {
        if (platform.selected && !platform.authorisation_confirmed) {
          this.validation.step1.authorised_all = false;
          break;
        }
      }

      // Validate Terms and conditions.
      //----------------------------------
      this.validation.step1.terms_valid = true;

      if (this.user.terms.required && this.user.terms.accepted == false) {
        this.validation.step1.terms_valid = false;
      }
    },

    validateRequired(field) {
      if (field.value !== "") {
        field.isValid = true;
        return true;
      }
      field.isValid = false;
      return false;
    },

    validateEmail(field) {
      let validEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (validEmail.test(field.value)) {
        field.isValid = true;
        return true;
      }
      field.isValid = false;
      return false;
    },

    validatePlatformSelection(platform) {
      // console.log("validatePlatformSelection");
      // console.log(platform);

      // Valid of not selected.
      if (!platform.selected) {
        // console.log("Patform " + platform.name + " not selected so valid");
        return true;
      }
      if (platform.authorisation_confirmed === false) {
        return false;
      }
      return true;
    },
    validateEmailMergeFieldsEntered() {
      if (
        !this.user.company_name.isValid ||
        !this.user.fca_number.isValid ||
        !this.user.firstname.isValid ||
        !this.user.surname.isValid
      ) {
        alert("Please complete your details first.");
        this.user.firstname.showValidation = true;
        this.user.surname.showValidation = true;
        this.user.telephone.showValidation = true;
        this.user.email.showValidation = true;
        this.user.company_name.showValidation = true;
        this.user.fca_number.showValidation = true;
        this.user.tpsp_internal_id.showValidation = true;
        return false;
      }
      return true;
    },

    platformChanged() {
      this.user.terms.required = false;

      for (const platform of this.platforms) {
        if (platform.available && platform.selected) {
          this.user.terms.required = true;
          break;
        }
      }
    },

    clickedBtnAcceptTandC() {
      // Show T&C modal
      this.$refs.modal_t_and_c.style.display = "block";
    },

    hideModal(event) {
      // hide t&C modal if click is on the mask.
      if (event.target == this.$refs.modal_t_and_c) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$refs["modal_t_and_c"].style.display = "none";
    },

    handleEmailLinkClick(platform) {
      if (this.validateEmailMergeFieldsEntered()) {
        // If validation passes, navigate to the link
        window.open(
          this.platformMailLink(platform),
          "_blank",
          "noopener noreferrer"
        );
        platform.authorise_clicked = true;
        // this.$forceUpdate();
      }
    },

    platformMailLink(platform) {
      let message = platform.authorisation.email.message;
      let cc_emails = this.email.alwaysCC;

      message = message.replace(/\n/g, "%0D%0A");
      message = message.replace("{company_name}", this.user.company_name.value);
      message = message.replace("{fca_number}", this.user.fca_number.value);
      message = message.replace("{firstname}", this.user.firstname.value);
      message = message.replace("{surname}", this.user.surname.value);

      // this field only shows for particular setups.
      if (this.tpsp_name !== "") {
        message = message.replace(
          "{tpsp_name}",
          "\nSoftware system: " + this.tpsp_name
        );
      } else {
        message = message.replace("{tpsp_name}", ".");
      }

      // console.log("add cc:" + this.platform_request_email_additional_cc);
      if (
        this.platform_request_email_additional_cc !== null &&
        this.platform_request_email_additional_cc !== ""
      ) {
        cc_emails = cc_emails + ";" + this.platform_request_email_additional_cc;
      }
      console.log(cc_emails);
      return (
        "mailto:" +
        platform.authorisation.email.to +
        "?subject=" +
        platform.authorisation.email.subject +
        "&body=" +
        message +
        "&cc=" +
        cc_emails
      );
    },

    capitalizeFirstPathPart() {
      // Remove leading and trailing slashes and split the URL by '/'
      const url = window.location.pathname;
      const parts = url.replace(/^\/|\/$/g, "").split("/");

      if (parts.length > 0) {
        // Get the first part and capitalize its first letter
        const firstPart = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);

        return firstPart;
      } // Return an empty string if the URL has no path parts
      return "";
    },

    // step3Back() {
    //   this.step = 2;
    //   this.$forceUpdate();

    //   this.setAppHeight("step2");

    //   gsap.fromTo(
    //     this.$refs["step2"],
    //     { autoAlpha: 0, x: 0 },
    //     { autoAlpha: 1, x: 0, duration: 0.5, ease: "power3.out" }
    //   );

    //   gsap.fromTo(
    //     this.$refs["step3"],
    //     { autoAlpha: 1, x: 0 },
    //     { autoAlpha: 0, x: 0, duration: 0.5, ease: "power3.out" }
    //   );
    //   this.$refs["step2"].classList.add("active");
    //   this.$refs["step3"].classList.remove("active");
    //   this.scrollTo("appTop");
    // },

    scrollTo(refName, behavior = "auto") {
      var element = this.$refs[refName];
      var reftop = element.offsetTop;

      window.scrollTo({ top: reftop, behavior: behavior });
    },

    handleResize() {
      this.setAppHeight("step" + this.step, 0, 0);
    },

    setAppHeight(refName, timeout = 300, duration = 0) {
      gsap.set(".container_footer", { autoAlpha: 0 });
      setTimeout(() => {
        let newHeight = this.$refs[refName].offsetHeight + 50;
        gsap.to(this.$refs.appTop, {
          height: newHeight + "px",
          duration: duration,
        });
        gsap.set(".container_footer", { autoAlpha: 1 });
      }, timeout);
    },

    async submit_to_api() {
      console.log({
        user: this.user,
        platforms: this.platforms,
        api_version: this.api_version,
        tpsp_name: this.tpsp_name,
      });

      let api_url = "https://finio.sprintenterprise.co.uk/api/post-form-tpsp.php";
      // let api_url = "https://finio.sprintenterprise.co.uk/api/post-form.php";
      // //If API version starts with 2 or is "both" then use the new API
      // if (
      //   this.api_version.startsWith("2") ||
      //   this.api_version == "both" || this.tpsp_name == "Development Site"
      // ) {
      //   api_url = "https://finio.sprintenterprise.co.uk/api/post-form-tpsp.php";
      // }


      this.$http
        .post(api_url, {
          user: this.user,
          platforms: this.platforms,
          api_version: this.api_version,
          tpsp_name: this.tpsp_name,
          finio_product_valuations: this.finio_product_valuations,
          finio_product_transactions: this.finio_product_transactions,
          finio_product_history: this.finio_product_history,
          finio_product_income_statements: this.finio_product_income_statements,
        })
        .then(function (response) {
          console.log(response);
        });
    },
  },
};
</script>

<style lang="scss">
#app {}

#steps {
  position: relative;
}

#step1,
#step2,
#step3 {
  // float: left;
  // clear: none;
  position: absolute;
  top: 0;
  opacity: 0;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  &.active {
    z-index: 2;
  }
}

.unavailable-platforms-wrapper {
  width: 100%;
  columns: 1 auto;
  margin-bottom: 20px;

  @media only screen and (min-width: 1060px) {
    columns: 2 auto;
  }
}

dl {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
}

dt {
  flex-basis: 5%;
  padding-right: 5px;
}

dd {
  flex-basis: 90%;
  flex-grow: 1;
  // flex-grow: 1;

  margin: 0 0 10px 0px;
}

.platformIsInvalid {
  border: 1px solid #990000;
}
</style>
